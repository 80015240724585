/*
 * FONTS
 */

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?i2hmjv');
  src:  url('../fonts/icomoon.eot?i2hmjv#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?i2hmjv') format('truetype'),
    url('../fonts/icomoon.woff?i2hmjv') format('woff'),
    url('../fonts/icomoon.svg?i2hmjv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e900";
}
.icon-file-check:before {
  content: "\e901";
}


/*
 * LAYOUT
 */

/* Wrapper */

html {
    overflow-y: hidden;
}

body{
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center top;
	font-family: Karla, arial, sans-serif;
	transition: all 0.5s ease;
	overflow-x: hidden;
	color: #4f4f4f;
}

body header.header,
body main.main,
body #left-sidebar{
	transition: all ease 0.5s;
}

body, html, #wrapper, body #left-sidebar{
	height: 100%;
    width: 100%;
}

body #right-content{
	padding-top:70px;	
}

#wrapper.open #right-content{
	transform: translateX(0);
	margin-right:280px;
}

#wrapper.open #right-content{
	transform: translateX(280px);
}

#page-wrapper{
	padding-left:0;
}

#wrapper{
	overflow-x:hidden;	
}
 
#wrapper.open{
    height: 100%;
    width: 100%;
}

#wrapper #left-sidebar .left-sidebar-wrapper{
	width:100%;
}

#wrapper.open #left-sidebar{
	left: 0;
}

body #left-sidebar{
	background-color: #f6f7f8;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    width: 280px;
    position: fixed;
    height: 100%;
    z-index: 999;
    left:0;
}

#wrapper #left-sidebar{
	transform: translateX(-280px);
}

#wrapper.open #left-sidebar{
	transform: translateX(0);
}

body #right-content{
	margin-left: 0;
    transition: transform ease 0.5s;
}

#wrapper main.main{
	padding-left:30px;
}

#wrapper.open main.main{
	padding-left:60px;
}



/* Alignment / Separation */

.mt-auto{
	margin-top: auto !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Links */

:focus {
	outline: 0 !important;
	box-shadow: none !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
}

a{
	color: #4f4f4f;
}

a:hover{
	text-decoration:none;
}

/* Title */

h2{
	margin-left: 5px;
}

h2 .sub-title{
	display:block;
	font-size:18px;
	position:relative;
	top:0;
}

body h3{
	font-size:15px;
	text-transform:uppercase;
}

.content-page h3{
	font-weight:600;
	margin-top:30px;
}


/* Dropdown */

.dropdown-menu{
	border: 0;
	background: #f6f7f8;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	margin-top: 15px;
	transition: margin-top 0.5s ease;
	display: block;
	visibility: hidden;
	top:0;
}

.dropdown-menu.show{
	margin-top: -15px;
	transition: margin-top 0.5s ease;
	visibility: visible;
}

.dropdown-item{
	font-weight: normal;
	font-size: 0.9rem;
	transform: translateX(0px);
    -moz-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transition: transform ease 0.5s;
}

.dropdown-item:hover{
	color: #fff;
	transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -webkit-transform: translateX(-15px);
}

/* Button */

.btn{
	border-radius: 2px;
	-webkit-border-radius:2px;
	-moz-border-radius: 2px;
	text-transform: uppercase;
	font-size: 16px;
	transition:all ease 0.5s;
	border:0;
}

.btn:disabled:hover{
	cursor:not-allowed;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:hover:focus{
	background:#4f4f4f;
}

.btn.btn-secondary{
	background:#4f4f4f;
}


.btn.btn-primary i{
	margin-right: 10px;
}

input.form-control{
	transition: all ease 0.5s;	
}
.form-label-group.focus input.form-control{
	border-color:transparent;
}

.form-label-group:after{
	content:'';
	width:0%;
	height:3px;
	display:block;
	transition: all ease 0.5s;
}

.form-label-group.focus:after{
	width:100%;	
}

#reset-form{
	padding-bottom:7px;	
}

.form-info{
	font-size:13px
}

/* Tooltip */

.tooltip > .tooltip-inner{
	font-size:11.5px;
}


/* Loader */
.loader{
	width: 100%;
    height: 100%;
    border-radius:3px;
	margin-top: 30px;
	background:#fff;
	position:absolute;
	top:0;
	left:0;
	z-index:1000;
	margin:0;
    display: flex;
    justify-content: center;
    align-items:center;
}

.loader img{
	width:60%;
	margin:0 auto;
	margin-bottom:50px;
	display:block;
}

.loader .wrapper{ 
    width: 200px;
    display:block;
}

.loading-bar{
	height: 2px;
	width:100%;
    margin: 0 auto;
    border-radius: 2px;
    background-color: #cfcfcf;
    position: relative;
    overflow: hidden;
    z-index: 200000;
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    transition: -webkit-transform .3s ease-in;
    transition: transform .3s ease-in;
    transition: transform .3s ease-in, -webkit-transform .3s ease-in;
}

.loading-bar .bar{
	height: 100%;
    width: 80px;
    position: absolute;
    -webkit-transform: translate(-34px,0);
    transform: translate(-34px,0);
    border-radius: 2px;
    -webkit-animation: initial-loading 1.5s infinite ease;
    animation: initial-loading 1.5s infinite ease;
}

#general-loader .loader .wrapper{
	margin:150px auto;
	position:relative;
}

#general-loader .loader{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

@keyframes initial-loading {
	0%, 100% {
	    -webkit-transform: translate(-34px,0);
	    transform: translate(-34px,0);
	}

	50% {
	    -webkit-transform: translate(150px,0);
	    transform: translate(150px,0);
	}
}

/* Modal */

.mfp-content video{
	width: 100%;
	display: block;
}

.mfp-ajax-holder.mfp-container .data-modal{
	padding: 20px;
}

.mfp-ajax-holder.mfp-container .data-modal .modal-category{
	font-size: 15px;
}

.mfp-ajax-holder.mfp-container .data-modal .modal-date,
.mfp-ajax-holder.mfp-container .data-modal .modal-copyright,
.mfp-ajax-holder.mfp-container .data-modal .modal-legend{
	font-size: 15px;
}

.mfp-ajax-holder.mfp-container .data-modal .modal-copyright,
.mfp-ajax-holder.mfp-container .data-modal .modal-legend{
	display:block;	
}

.mfp-ajax-holder.mfp-container .data-modal .modal-title{
	font-size: 15px;
	text-transform: uppercase;
	line-height: 17px;
	margin-bottom: 10px;
}

.mfp-ajax-holder.mfp-container .data-modal .modal-description{
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 35px;
}

.mfp-ajax-holder.mfp-container .data-modal .share-title{
	text-transform: uppercase;
	font-weight: bold;
	font-size: 15px;
	margin-bottom: 10px;
}

.mfp-ajax-holder.mfp-container .data-modal .form-control,
.mfp-ajax-holder.mfp-container .data-modal .input-group-text{
	background: #f6f7f8;
	border: 0;
	color: #9d9e9e;
}

.mfp-ajax-holder.mfp-container .data-modal .form-control{
	padding-left: 0;
	font-size: 13px;
}

.mfp-ajax-holder.mfp-container .data-modal .dropdown-menu.show{
	margin-top: 0;
	margin-left: 50px; 
}

.mfp-ajax-holder.mfp-container .data-modal .btn{
	margin-top: 20px;
}

.mfp-ajax-holder .mfp-content > .container{
	background:#fff;
    position: relative;
}

.mfp-ajax-holder .mfp-content .data-modal{
	padding:30px 20px
}

.mfp-ajax-holder.mfp-container .mfp-content .mfp-close, 
.mfp-ajax-holder .mfp-close:active{
	color:#fff;
	top:-28px;
}

.mfp-ajax-holder.mfp-container .mfp-content video{
	display:flex;
	border-bottom:1px solid #000;
	border-bottom-style: inset;
}

.mfp-ajax-holder.mfp-container .mfp-content > .container{
	max-width:1024px;
}


.mfp-ajax-holder .mfp-content .view-pictures .data-modal{
	padding:20px;
}

.mfp-ajax-holder.mfp-container .view-pictures .metadata .modal-date,
.mfp-ajax-holder.mfp-container .view-pictures .metadata .modal-copyright,
.mfp-ajax-holder.mfp-container .view-pictures .metadata .modal-legend{
	font-size: 13px;
	line-height:14px;
}

.mfp-ajax-holder.mfp-container .view-pictures .metadata .modal-copyright{
	margin-top:5px;
}

.view-pictures .picture-legend{
	display:flex;
}

.mfp-ajax-holder.mfp-container .view-pictures .data-modal .modal-description,
.mfp-ajax-holder.mfp-container .view-pictures .data-modal .btn{
	margin:0;
}

.mfp-ajax-holder.mfp-container .view-pictures > .row > div:first-child,
.mfp-ajax-holder.mfp-container .view-movies > .row > div:first-child{
	padding:0;
	background:#000;
	text-align:center 
}

.picture-legend .data-modal:last-child{
	margin-left:auto
}

/* Carousel */

.nav-carousel{
	position: relative;
	top:-3px;
	left: 25px;
}

.owl-carousel{
	margin-bottom:50px;
}

.nav-carousel-arrow i{
	font-size: 11px;
}

body .owl-carousel .owl-stage-outer{
	overflow: visible;
}

.nav-carousel-slide-next{
	display: block;
	height: 347px;
	width: 100px;
	color: rgba(79, 79, 79, 0.3);
	position: absolute;
	right: 0;
	z-index: 2;
	text-align: center;
	line-height: 370px;
	font-size: 25px;
	background: #fff;
	opacity: 0.7;
	display:none;
}

.nav-carousel-slide-next:hover{
	color: rgba(79, 79, 79, 1);
}

/* Form */

.form-label-group{
	position: relative;
	margin-bottom: 1.2rem;
}

.form-control{
	padding: 1.25rem .75rem;
	border-radius: 2px;
	line-height:42px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.inline-checkbox .custom-checkbox{
	float:left;
	margin-left:10px;
}

.has-error input, .has-error select, .has-error textarea{
	border-color:red;
}

textarea.form-control{
	line-height: 22px;
    padding: 10px;
    min-height: 160px;
}

.form-error ul{
	list-style:none;
	padding-left:0;
	font-size:13px;
	margin:0;
}

.form-error ul li{
	color:red;
}

/* Datepicker */

input.datepicker-input{
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
}

.datepicker-dropdown{
	visibility:visible;
	background:#fff;
	color:#6b747d
}

body .datepicker-dropdown:before{
	display:none;
}

body .datepicker{
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
}

body .datepicker table tr td.active,
body .datepicker table tr td.active.highlighted{
	text-shadow:none;
}

body .datepicker table tr td, .datepicker table tr th{
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;;
}

/* Select 2 */

body .select2-container--default .select2-selection__rendered .select2-selection__placeholder{
	color:#6b747d;
	opacity:1;
}

body .select2-container .selection .select2-selection .select2-selection__rendered {
	color:#495057;
}

.select2-container .selection .select2-selection{
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	height:42px;
	border-color: #ced4da;
}

.select2-container .selection .select2-selection .select2-selection__rendered{
	line-height:39px;
	color:#000;
}

.select2-container .selection .select2-selection .select2-selection__arrow{
	height:39px;
}

.select2.select2-container{
	max-width:100%;
	width:100% !important;
}

.select2-container--open .select2-dropdown--below{
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
}

body .select2-dropdown{
	border-color: #ced4da;
}

body .select2-container--default.select2-container--focus .select2-selection--multiple{
	border-color: #ced4da;
}

body .select2-container .select2-search--inline .select2-search__field{
	margin-top:0;
}

body .select2-container--default .select2-selection--multiple .select2-selection__choice{
    border: 0;
    padding: 0 5px;
    margin:0;
    margin-top: 8px;
    margin-left: 5px;
    line-height: 24px;
    font-size: 13px;
    color: #fff;
}

body .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
	color:#fff;
}

body .select2-container--default .select2-selection--multiple .select2-selection__clear{
	margin-top:0;
	margin-bottom:0;
}

body .select2-container .select2-selection--multiple .select2-selection__rendered{
	overflow: hidden;
    overflow-y: scroll;
    max-height: 40px;
}

/* Step nav */

.nav-pills{
	margin-top:50px;
	margin-bottom:40px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
}

.nav-pills .active .badge-primary{
	background:#fff;
}

/* Alert */

.alert ul{
	margin:0;
	list-style:none;	
	padding:0;
}

.alert{
	border:0;
	border-radius:0;
	-moz-border-radius:0;
	-webkit-border-radius:0;
	text-align:center;
}

.alert.alert-danger{
	background:rgba(0, 0, 0, 0.1);
	color:rgba(0, 0, 0, 0.5);
}


/* Images */

.img-responsive{
	max-width:100%;
}


/* Table */

.table thead th{
	border:0;
	color:#fff;
	text-transform:uppercase;
	font-size:13px;
}

.table tbody td{
    font-size: 15px;
}

.table tbody tr:nth-child(odd){
	background:#fff;
}

.table tbody tr:nth-child(even){
	background:#f6f7f8
}

/*
 * HEADER
 */

.header {
	grid-area: header;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 0 6px;
	height:70px;
	z-index:100;
	position:fixed;
	width:100%;
	top:0;
}

#wrapper.open .header .logo{
	opacity: 0;
	transition: all ease 0.5s;
}

#wrapper .header .logo{
	opacity:1;
	max-height: 40px;
}

.wrapper-toggle-sidebar{
    padding: 15px;
    background:#fff;
    margin-top:-5px;
}

.wrapper-toggle-sidebar:hover{
	cursor:pointer;
}

.toggle-sidebar{
    width: 27px;
    height: 27px;
	display:block;
	position: relative;
}

.toggle-sidebar span{
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #4f4f4f;
    opacity: 1;
    left: 0;
    top: 14px;
}

#wrapper .toggle-sidebar {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#wrapper .toggle-sidebar span:nth-child(1) {
    top: 5px;
}

#wrapper.toggle-sidebar span:nth-child(3),
#wrapper .toggle-sidebar span {
    top: 12px;
}

#wrapper .toggle-sidebar span:nth-child(3), 
#wrapper .toggle-sidebar span {
    top: 12px;
}

#wrapper .toggle-sidebar span:nth-child(4) {
    top: 19px;
}

#wrapper.open .toggle-sidebar span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
}

#wrapper.open .toggle-sidebar span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#wrapper.open .toggle-sidebar span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#wrapper.open .toggle-sidebar span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#top-right-nav{
	display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left:auto;
    margin-right: 0;
}

.select-language{
	padding: 0 20px;
}

.select-language > a{
	position: relative;
	text-decoration: none;
	padding: 0 0 20px 0;
}

.select-language > a > i{
	position: absolute;
	top: 20px;
    right: 5px;
    font-size: 8px;
}

.select-language .dropdown-menu.show{
	margin-top: -5px;
	margin-left: -125px;
}

#menu-icon > li{
	margin-right: 10px;
}

#menu-icon > li > a{ 
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	color: #4f4f4f;
	padding:0;
}

#menu-icon > li > a:hover,
#menu-icon > li > a:focus{ 
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.10);	
}

#menu-icon > li a > i.icon-file-check,
#menu-icon > li a > i.fa-cloud-download-alt{
	font-size: 20px;
	color: #fff;
	position: relative; 
	top:2px;
}

#menu-icon > li:last-child > a{ 
	background:#f6f7f8;
}

#menu-icon > li > a img{
	position: relative;
	top:-3px;
}

#menu-icon > li > a#cart-link{
	position:relative;
}

#menu-icon > li > a#cart-link #cart-counter{
    display: block;
    position: absolute;
    bottom:-11px;
    left: 0;
    border-radius: 100%;
    color: #fff;
    height: 25px;
    line-height: 25px;
    width: 25px;
    font-weight: bold;
    font-size: 14px;
    transition:background ease 1s; 
} 

#menu-icon > li a#cart-link #cart-counter.change{
	background: #f4099a;
}


#cart{
	position: fixed;
    top: 81px;
    min-width: 300px;
    right: 15px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
    text-align:center;
    background:#fff;
    display:none;
}

#cart.open{
	display:block;
}

#cart a.link,
#cart a.link:hover,
#cart a.link:focus{
	box-shadow:none;
	background:none;
}

#cart a.link:hover,
#cart a.link:focus{
	text-decoration:underline;	
}

#cart button{
	width:100%;
}

#cart h2{
	font-size:20px;	
}

#cart #delete-cart-document{
	background:none;
	font-size: 17px;
    position: relative;
    left: 10px;
    color:#4f4f4f;
}

#cart .dropdown-menu{
	margin-top:-5px;
	left:30px !important
}

/*
 * FOOTER
 */

.footer {
	grid-area: footer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
}


/*
 * LEFT NAV
 */

/* Menu */

#left-sidebar #sidebar-header{
	height:70px;
}

#left-sidebar ul.nav .nav-link{
	padding: .5rem 0;
	font-size: 16px;
	color: #4f4f4f
}

#left-sidebar ul.nav span.nav-link{
	opacity:0.5;	
}

#left-sidebar ul.nav span.nav-link:hover{
	cursor:not-allowed;
}

#left-sidebar ul.nav .nav-link i{
	margin: 0 20px 0 40px;
	font-size: 20px;
}

#left-sidebar ul.nav .nav-link .fa-chevron-right{
	font-size: 12px;
	float: right;
	margin-right: 40px;
	height: 25px;
    line-height: 25px;
	transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transition: transform ease 0.3s;
}

#left-sidebar ul.nav li.nav-item.toggle .nav-link .fa-chevron-right{
	transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}

#left-sidebar .left-sidebar-gutter #toggle-menu:after{
	content: '';
	position: relative;
	top: 5px;
}

/* Sub Menu */

#left-sidebar ul.nav li .sub-menu{
	background: #eeeff0;	
	padding: 0;
	display: none;
}

#left-sidebar ul.nav li .sub-menu ul{
	padding: 10px 0;
	list-style: none;
}

#left-sidebar ul.nav li .sub-menu ul li a{
	font-size: 14px;
	padding: 5px 30px;
	display: block;
	transform: translateX(0px);
	-moz-transform: translateX(0px);
	-webkit-transform: translateX(0px);
	transition: transform ease 0.5s;
	position: relative;
	text-transform:uppercase;
}

#left-sidebar ul.nav li .sub-menu ul li > a:hover,
#left-sidebar ul.nav li .sub-menu ul li > a:focus,
#left-sidebar ul.nav li .sub-menu ul li.active > a{
	color: #fff;
}

#left-sidebar ul.nav li .sub-menu ul li .sub-categories{
	display:none;
}

#left-sidebar ul.nav li .sub-menu ul li .sub-categories a{
	font-size:13px;
	padding: 3px 30px;
	text-indent:5px;
}

#left-sidebar ul.nav li .sub-menu ul li .sub-categories li.active a{
	background:none;
	font-weight:bold;
}

#left-sidebar ul.nav li .sub-menu ul li.active .sub-categories{
	display:block;
}

/* Bottom Links */

#left-sidebar #bottom-nav{
	padding: 20px;
}

#left-sidebar #bottom-nav .separator{
	height: 1px;
	width: 25px;
	background: #c4c4c5;
	margin: 15px auto
}

#left-sidebar #bottom-nav ul{
	margin: 0;
	margin-bottom:15px;
	display:block;
}

#left-sidebar #bottom-nav .nav-item .nav-link{
	text-align: center;
	font-size: 14px;
	padding: .3rem 0;
}

#left-sidebar #bottom-nav .logos{
	text-align: center;
}

#left-sidebar #bottom-nav .logos > a{
	display: inline-block;
	margin: 10px;
}

#left-sidebar #bottom-nav .logos > a img,
body#authentication .logos a img{
	height: 30px;
  	-webkit-filter: grayscale(100%);
  	filter: grayscale(100%);
	opacity: 0.4;
	transition: all ease 0.5s;
}

#left-sidebar #bottom-nav .logos > a:hover img,
body#authentication .logos a:hover img{
	-webkit-filter: none;
  	filter: none;
	opacity: 1;
}

#bottom-nav #legal-link .nav-item .nav-link{
	font-size: 12px;
	text-align: center;
	padding: 0;
}

#left-sidebar .wrapper-logo{
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 70px;
}

#left-sidebar .wrapper-logo .logo{
    max-height:45px;
} 

#left-sidebar #sidebar-content{
	-ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 80px);
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    padding-top:15px;
}

#left-sidebar .left-nav-menu{
    position: relative;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    list-style: none;
}

#left-sidebar .select-language{
	text-align:center;
	margin-bottom:10px;
	display:none;
}

#left-sidebar #left-nav-menu{
	display:block;
}

/*
 * MAIN
 */

.card.movie .cover:before{
	content: url(../img/frontend/player.png);
	position: absolute;
  	width: 42px;
	height: 42px;
	top:0; left: 0; right: 0; bottom: 0;
	margin: auto;
}

.card{
	width: 225px;
	border: 0;
	margin: 20px 10px;
	box-shadow: 0 -7px 15px rgba(0, 0, 0, 0.05);
}

.card.movie .cover{
	position: relative;
}

.card img{
	width: 100%;
}

.card.movie .cover:hover,
.card.movie .cover:focus{
	text-decoration: none;	
}

.card .cover .category,
.card .cover:hover .category,
.card .cover:focus .category{
	color: #fff;
	text-decoration: none;
	font-size: 12px;
	text-align: center;
	padding: 0px;
}

.card .cover .category{ 
	position: absolute;
	left: 0; right: 0; bottom: 0;
	margin: auto;
	max-width: 60%;
}
 
.card-header,
.card-footer{
	background: #fff;
	border: 0;
	text-align: center;
}

.card-footer{
	padding: 0;	
}

.card-footer .icon-download,
.table .actions a{
	font-size: 20px;
    position: relative;
    top: -2px;
}

.table .actions a{
	margin-right:10px;
	top:0;
	display:block;
	height:100%;
	float:left;
}


.card-title{
	text-transform: uppercase;
	font-size: 15px;
	margin: 0;
}

.card-body{
	padding-bottom: 0.5rem;
}

.card-footer ul{
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 0;	
	margin: 0;
}

.card-footer ul li{
	width: 33%;	
}

.card-footer ul li > a{
	padding: 10px 0;
    display: block;
	font-size: 25px;
}

.card-footer .dropdown-menu{
	margin-left: -50px
}

.card.picture .thumbs{
	display:flex;
	justify-content: center;
	padding:0;
	margin-top:-30px;
	margin-bottom:0;
	list-style:none;
}

.card.picture .card-footer{
	min-height:20px;
}

.card.picture .thumb{
	max-width:80px;
	border:4px solid #fff;
	margin:4px;
}

.card .card-title{
	text-align:center;
}

.card.picture .card-body{
    padding: 0.8rem 0.8rem 0 0.8rem;
}

.card.picture .card-footer ul li > a{
	padding:0 0 10px 0;
}

.card .copyright{
	text-align:center;
	font-style:italic;
	font-size:15px;
}

.card.sound .wrapper-audio,
.card.documents .wrapper-documents,
.card.movie .no-thumb-wrapper{
	width:100%;
	max-width:90%;
	margin:0 auto;
	padding:0 0 30px 0;
	position:relative;
	text-align:center;
}

.card.movie .no-thumb-wrapper,
.card.documents .wrapper-documents{
	min-height:100px	
}

.card.sound audio{
	width:100%;
}

.card.documents .wrapper-documents i,
.card.movie .no-thumb-wrapper i{
	font-size:40px;	
}

.view-documents embed{
	width:100%;
	min-height:800px;
}

.card.picture .cover,
.owl-carousel .owl-item .card.picture .cover{
	background: #000;
	text-align:center;
	overflow:hidden;
	position:relative;
}

.card.picture img,
.owl-carousel .owl-item .card.picture img{
	height:150px;
	max-width:100%;
	width:auto;
	margin:0 auto;
}

.masonry-item .masonry-item-overlay,
.owl-item .masonry-item-overlay{
  	background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    transition: top ease 0.2s;
    display: flex;
    justify-content: center;
    bottom:150px;
    left: 0;
  }
  
  .masonry-item:hover .masonry-item-overlay,
  .owl-item:hover .masonry-item-overlay{
  	bottom:0px;
  }	
 
 
  .masonry-item .masonry-item-overlay p,
  .owl-item .masonry-item-overlay p{
  	text-align:center;
  	padding:12px;
  	margin:0;
  	display:flex;
    align-items: center;
  }
  
  .masonry-item .masonry-item-overlay a,
  .owl-item .masonry-item-overlay a{
  	display:block;
  	width:40px;
  	height:40px;
  	border:2px solid #fff;
  	border-radius:100%;
  	line-height:40px;
  	text-align:center;
  	transition:all ease 0.2s;
  }
  
  .masonry-item .masonry-item-overlay a:hover,
  .owl-item .masonry-item-overlay a:hover{
  	background:#62b0df;
  	border-color:#62b0df;
  }
  
  .masonry-item .masonry-item-overlay a i,
  .owl-item .masonry-item-overlay a i{
  	color:#fff;
  	font-size:19px;
  }
  
  .masonry-item .masonry-item-overlay a i.la-eye,
  .owl-item .masonry-item-overlay a i.la-eye{
  	position:relative;
  	top:2px;
  }

/* Pagination */

body .pagination,
body .pagination li,
body .pagination li span,
body .pagination li a,
body .pagination li a:hover{
	border:0;
	color: #4f4f4f;
}

body .pagination .page-link{
    padding: 0.3rem .50rem;
    font-size: 14px;
}

body .pagination{
	display:flex;
	justify-content:center;
}

/*
 * AUTHENTICATION
 */

body#authentication {
	background-image: url("../img/frontend/cover-auth.jpg");
	background-size: 100% auto;
    background-position: 0 0;
}

body#authentication #wrapper{
	padding-top: 235px;
    padding-bottom: 40px;
}

body#authentication .logo{
	max-width: 190px;
	padding-bottom: 0;
}

body#authentication .select-language{
	display: flex;
	justify-content: center;
}

body#authentication .select-language .dropdown-menu.show{
	margin-left: 0;
}

body#authentication .login-txt{
	line-height: 17px;
}

body#authentication .login-txt b{
	text-transform: uppercase;
}

body#authentication .logos a{
	margin: 30px 0;	
}

body#authentication .logos a img{
	height: auto;
}

body#authentication hr{
	position: absolute;
	left: -100%;
	width: 300%;
	margin: 0 50px;
}

body#authentication .nav-pills .nav-item:hover{
	cursor:default;
}


/*
 * PICTURE
 */
 
 #picture-wrapper h2{
 	margin:0;
 	font-size:1.8rem;
 	line-height: 1.8rem;
 }

 #picture-wrapper h2 b{
 	margin-top:10px;
 }
 
 #picture-wrapper h2 .sub-title{
 	font-size:17px;
 }
 
 #picture-wrapper .description,
 #picture-wrapper .metadata{
 	font-size:13px; 
 	line-height:16px;
 	font-weight:normal;
 }
 
 #picture-wrapper .masonry-list{
 	padding-top:20px;
 }
 
 #picture-wrapper .metadata{
  	padding:0;
  	list-style:none;
 }
 
 #picture-wrapper .masonry-list .masonry-item > a{
 	display:block;
 	max-width: 280px
 }
 
 #picture-wrapper .masonry-list .masonry-item{
 	margin-bottom:20px;
 	position:relative;
 }
 
 #picture-wrapper .masonry-list .masonry-item img{
  	display:block;
  	width:100%; 
  }

 #picture-wrapper #selectNumberPhotoLabel{
 	margin-left:20px;
 }
 
 #picture-wrapper .form-label-group{
 	margin-bottom:0;
 }
 
 #picture-wrapper .masonry-list .masonry-item:hover .overlay,
  #picture-wrapper .masonry-list .masonry-item:focus .overlay{
 	display:flex;
 }
 
 #picture-wrapper .masonry-list .masonry-item .overlay{
 	background:rgba(0,0,0,0.65);
 	position:absolute;
 	top:0;
 	left:0;
 	width:100%;
 	height:100%;
 	z-index:0;
 	display:none;
 	justify-content:center;
 	align-items:center;
 }
 
 .masonry-list .masonry-item .picture-action{
    color: transparent;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 26px !important;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 3px solid #192a6b;
    text-align: center;
    font-size: 14px;
    background: transparent;
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
 }

 .masonry-list .masonry-item .selected-icon{
 	width:25px;
 	height:25px;
 	display:none;
 	position:absolute;
 	right:15px;
 	bottom:15px;
 	background:red;
 	color:#fff;
 	line-height:25px;
 	text-align:center;
 	font-size:11px;;
 	-webkit-box-shadow: 10px 13px 25px 0px rgba(0,0,0,0.51);
	-moz-box-shadow: 10px 13px 25px 0px rgba(0,0,0,0.51);
	box-shadow: 10px 13px 25px 0px rgba(0,0,0,0.51);
 }
 
 .masonry-list .masonry-item.selected .picture-action{
 	color:#fff;
 	background: #192a6b;
 }
 
 
 /*
  * SHARE
  */
  
  body.frontend_share_view #wrapper > .container{
  	background: #fff;
    padding: 30px;
    border-radius: 4px;  
  }
 
  body#authentication.frontend_share_view #wrapper {
    padding-top: 295px;
  }
  
  .table-responsive{
  	max-width:95%;
  }
  
  .table td, .table th{
  	padding:.45rem .45rem;
  	text-align:center;  	
  	border:0;
  }
  
  #share_cart_type_expirationDate_date,
  #share_cart_type_expirationDate_time{
  	display:flex;
  }
  
  #share_cart_type_expirationDate_date > .select2-container,
  #share_cart_type_expirationDate_time > .select2-container{
  	margin:0 10px;
  }
  
  #shareToFlicker{
  	align-items:center;
  	max-width:75%;
  }
  
  #shareToFlickerButton{
  	width:100%;
  }
  
  #shareToFlicker p{
  	flex:0.7;
  	margin:0;
  }
    
  #shareLoader{
  	width:100%;
  	height:40px;
  	background:#f6f7f8;  	
  	display:none;
  }
  
  #shareLoader #loading{
  	background:#42b8eb;
  	width:0%;
  	height:40px;
  	line-height:40px;
  	text-align:center;
  	color:#192a6b;
  	transition:width ease 0.5s;
  }
  
  #shareWrapperLoader{
  	width:100%;
  	flex: 1;
  }
  
/*
 * MFP
 */
 
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-container .mfp-content .mfp-close,
.mfp-close:active{
	opacity:1;
	width:28px;
	height:28px;
	line-height:28px;
	text-align:center;
	padding:0;
	margin:0;
	right:-28px;
	top:12px;
	position:absolute;
}

/*
 * SCROLL
 */
 
::-webkit-scrollbar {
    width: 6px;
} 

::-webkit-scrollbar-thumb {
    border-radius: 0;
}

::-webkit-scrollbar-track {
   background:#fff;
}

/*
 * PAGE
 */
 
#frontend_user_files{
	background:none;
}

#frontend_user_files #history i{
	font-size:19px;
}

#frontend_user_files main .row > .container{
	max-width:90%
}

#frontend_user_files table td{
	vertical-align:middle;
}

/*
 * RESPONSIVE
 */
@media (min-width: 992px){
	.modal-lg, .modal-xl {
		max-width: 900px;
	}
	
	#left-sidebar .wrapper-toggle-sidebar{
		display:none;
	}
}

@media (max-width: 992px){
		
	.mfp-ajax-holder.mfp-container .mfp-content .mfp-close, 
	.mfp-ajax-holder .mfp-close:active{
		right:0;
		top: 0;
		z-index: 2;
	}
	
	.picture-legend .data-modal:last-child{
		margin-left:0;
		width:100%;
	}
	
	body #right-content{
	    padding-top: 66px;
	}
	
	#wrapper main.main {
    	padding: 10px;
	}	
	
	#wrapper.open #right-content{
		margin-right:0;
	}	
}

@media (max-width: 575px){
	body{
		overflow-x: hidden;
	}
	
	body#authentication{
		background-position: 0 -90px;
		background-size:auto;
	}
	
	body#authentication #wrapper{
		display:block;
	    padding-top: 76px;
    	padding-bottom: 15px;
	}
	
	body#authentication .form-login{
		margin-top: 50px;
	}
	
	body#authentication .logos{
		display: flex;
		justify-content: center;
		text-align: center;
	}
	body#authentication .logos a{
		display: block;
		float: none !important;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 0;
	}
	
	#top-right-nav .select-language,
	#menu-icon,
	.nav-carousel-slide-next{
		display:none;
	}
	
	.masonry-list .masonry-item,
	.masonry-list .masonry-item .card{
		width:100%;
		margin: 20px 0;
	}
	
}